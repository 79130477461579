import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Seo from '../../components/Seo'
import BigTitle from '../../components/BigTitle'
import DefaultSection from '../../components/DefaultSection'
import ContactSection from '../../components/ContactSection'
import CardDeck from '../../components/CardDeck'
import Card from '../../components/Card'
import NavigationLink from '../../components/NavigationLink'
import classNames from 'classnames'

const Page = ({ data }) => {

  const publications = data.allPublikationenJson.edges.map(publicationNode => publicationNode.publication)
  const allCategories = [...new Set(publications.map(p => p.category))].sort()
  const [selectedCategories, setSelectedCategories] = useState([])

  const updateSelectedCategories = (category) => {
    if (selectedCategories.indexOf(category) === -1) {
      setSelectedCategories([category])
    } else {
      setSelectedCategories([])
    }
  }

  return (
    <React.Fragment>
      <Seo title="Publikationen"
           description="Erfahren Sie mehr über uns und schauen Sie sich unsere Artikel, Vorträge und weitere Veröffentlichungen an."
      />
      <BigTitle title="Publikationen" className="mask-black" imageRelativePath={'Titelbild_Architektur.jpg'}
                textInverted={true} menuInverted={true} logoInverted={true}>
        Erfahren Sie mehr über uns und schauen Sie sich unsere Artikel, Vorträge und weitere Veröffentlichungen an.
      </BigTitle>
      <DefaultSection>
        <div className="d-flex justify-content-center justify-content-lg-start">
          <div className="btn-group btn-group-sm btn-group-toggle mb-3"
               role="group">{allCategories.map((cat, index) => (
            <button className={classNames('btn btn-outline-dark', { active: selectedCategories.indexOf(cat) !== -1 })}
                    onClick={() => updateSelectedCategories(cat)} key={'filter-btn-' + index}>{cat}</button>
          ))}
          </div>
        </div>
        <CardDeck cardsPerRow={3}>
          {publications.filter(p => selectedCategories.length === 0 || selectedCategories.indexOf(p.category) !== -1).map((p, index) => {
            //const publishingDate = toDate(p.publishingDate)
            const footer =
              <div className="d-flex justify-content-end">
                {/*<span className="text-muted" style={{ fontSize: '75%' }}>{formatDateShort(publishingDate)}</span>*/}
                <small>
                  <span className="badge badge-pill badge-brand mr-1">{p.category}</span>
                  <span className="badge badge-pill badge-secondary">{p.mediaDescription}</span>
                </small>
              </div>
            return <Card imageRelativePath={p.image} imageAlt={''} footer={footer} key={'p-card-' + index}>
              <small className="text-muted">{p.author}</small><br />
              <NavigationLink to={p.link} asTextLink>
                <h6 className="card-title text-black">{p.title}</h6>
              </NavigationLink>
            </Card>
          })}
        </CardDeck>
      </DefaultSection>
      <ContactSection className="bg-level-1" />
    </React.Fragment>
  )
}

export default Page

export const query = graphql`
    {
        allPublikationenJson(sort: {fields: publishingDate, order: DESC}) {
            edges {
                publication: node {
                    id
                    title
                    author
                    category
                    mediaDescription
                    link
                    image
                    publishingDate
                }
            }
        }
    }
`
